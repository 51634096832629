import React, { useState, useEffect } from 'react'
import { getMenuListAction, getElemsAuthAction } from '../../store/menu/action'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'


import styles from './Sidebar.module.css'

import { useHistory } from 'react-router-dom'


import { Menu } from 'antd'

const { SubMenu } = Menu

interface ListItem {
  parentId: string,
  id: string,
  name: string,
  menuItemKey: string,
  menuPath?: string,
  menuIcon?: string,
  children?: any[]
}

export const Sidebar: React.FC = () => {
  const history = useHistory()
  const [list, setList] = useState<ListItem[]>([])
  const [selectedKeys, setSelectedKeys] = useState<any>([])
  const [openKeys, setOpenKeys] = useState<any>([])

  const menuList = useSelector((state: RootState) => {
    return state.menu.list
  })
  const dispatch = useDispatch()

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    initMenuList()
  }, [menuList, history.location.pathname])

  // useEffect(() => {
  //   initMenuList()
  // }, [])

  const init = () => {
    getMenuList()
  }

  const getMenuList = () => {
    dispatch(getMenuListAction({}))
  }

  const initMenuList = () => {
    const curPathName = history.location.pathname
    setList(menuList)
    const menuSelectedData = findCurMenu(menuList, curPathName)
    console.log('menuSelectedData', menuList, curPathName, menuSelectedData)
    setSelectedKeys(menuSelectedData.selectedKeys)
    setOpenKeys(menuSelectedData.openKeys)
    // 获取按钮权限
    if (menuSelectedData.selectedMenuItemKey) {
      dispatch(getElemsAuthAction({
        menuItemKey: menuSelectedData.selectedMenuItemKey,
      }))
    }
  }

  // 
  const findCurMenu = (list, name) => {
    console.log('adadafd', list, name)
    if (!list) {
      return {
        selectedKeys: [],
        selectedMenuItemKey: '',
        openKeys: [],
      }
    }
    
    let menuList = JSON.parse(JSON.stringify(list))

    let selectedKeys:any = []
    let openKeys:any = []

    for (let i = 0, len = menuList.length; i < len; i++) {
      const menu = menuList[i]
      if (menu.menuPath === name) {
        selectedKeys = [menu]
        // openKeys = [menu.id]
        break;
      }

      const submenuList = menu.children
      if (submenuList && submenuList.length) {
        for (let j = 0, lenJ = submenuList.length; j < lenJ; j++) {
          const submenu = submenuList[j]
          if (submenu.menuPath === name) {
            selectedKeys = [submenu]
            openKeys = [menu.id]
            break;
          }

          const thirdmenuList = submenu.children
          if (thirdmenuList && thirdmenuList.length) {
            for (let k = 0, lenK = thirdmenuList.length; k < lenK; k++) {
              const thirdmenu = thirdmenuList[k]
              if (thirdmenu.menuPath === name) {
                selectedKeys = [thirdmenu]
                openKeys = [menu.id, submenu.id]
                break;
              }
            }
          }
        }
      }
    }

    if (!selectedKeys.length) {
      const menuId = history.location.pathname.split('/')[1]
      
      for (var j = 0, menuLen = menuList.length; j < menuLen; j++) {
        const menu = menuList[j]

        if (menu.menuPath && menuId === menu.menuPath.split('/')[1]) {
          selectedKeys = [menu]
          break
        }
        for (var i = 0, len = menu.children.length; i < len; i++) {
          const submenu = menu.children[i]
          if (menuId === submenu.menuPath.split('/')[1]) {
            selectedKeys = [submenu]
            break
          }
        }
      }
    }
    return {
      selectedKeys: selectedKeys.map(i => i.id),
      selectedMenuItemKey: selectedKeys.map(i => i.menuItemKey).join(','),
      openKeys,
    }
  }

  const onSelect = (event) => {
    const record = event.item.props['data-item']
    // if (selectedKeys.includes(record.id)) {
    //   return
    // }
    setSelectedKeys([record.id])
    history.push(record.menuPath)
    // 获取按钮权限
    // dispatch(getElemsAuthAction({
    //   menuItemKey: record.menuItemKey,
    // }))
  }

  const onOpenChange = (openKeys) => {
    setOpenKeys(openKeys)
  }

  return (
    <div className={ styles.sidebar }>
      <Menu 
        mode="inline" 
        selectedKeys={ selectedKeys }
        openKeys={ openKeys }
        onOpenChange={ onOpenChange }
        style={{ width: '100%', height: '100%' }} 
        onClick={ onSelect }
      >
        {
          list?.map((item) => {
            if (item.children && item.children.length) {             
              return (
                <Menu.SubMenu 
                    key={ item.id } 
                    icon={ item.menuIcon ? <i className={ `${ styles.iconfont } menufont ${ item.menuIcon }`}></i> : null } 
                    title={ item.name }
                    >
                  {
                    item.children?.map((record2) => {
                      if (record2.children && record2.children.length) {
                        return (
                          <Menu.SubMenu
                            key={ record2.id } 
                            title={ record2.name }
                            >
                            {
                              record2.children?.map((record3) => {
                                return (
                                  <Menu.Item 
                                    data-item={ record3 }
                                    key={ record3.id }
                                  >{ record3.name }
                                  </Menu.Item>
                                )
                              })
                            }
                          </Menu.SubMenu>
                        )
                      } else {
                        return (
                          <Menu.Item 
                            data-item={ record2 }
                            key={ record2.id }
                            >{ record2.name }
                          </Menu.Item>
                        )
                      }
                      
                    })
                  }
                </Menu.SubMenu>
              )
            }
            else {
              return (
                <Menu.Item 
                  key={ item.id }
                  data-item={ item }
                  icon={ item.menuIcon ? <i className={ `${ styles.iconfont } menufont ${ item.menuIcon }`}></i> : null }
                >{ item.name }</Menu.Item> 
              )
            }
          })
        }
      </Menu>
    </div>
  )
}

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Sidebar)