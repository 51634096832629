import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal, Tooltip } from 'antd'
import styles from './TechnicPriceModal.module.css'
import { CinemaScreenTypeItems, VideoInfo } from '../../../../libs/util.constants'
import picTemplate from '../../../../assets/images/ad-exec/pic-template.jpg'
import util from '../../../../libs/util'

interface Props {
  childRef: any,
  mallOrderPlanId?: string,
  mallOrderMediaId?: string,
}

export const TechnicPriceModal: React.FC<Props> = ({ childRef, mallOrderPlanId, mallOrderMediaId }) => {
  useImperativeHandle(childRef, () => ({
    onToggle() {
      showEffectModal()
    }
  }))
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showEffectModal = () => {
    setIsModalVisible(true)
  }

  const [isFold, setIsFold] = useState(true)
  const toggle = () => {
    setIsFold(!isFold)
  }

  // 详情
  const [ad, setAd] = useState<any>()
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [hasFreeFee, setHasFreeFee] = useState(false)
  const [feeAmount, setFeeAmount] = useState(0)
  const [fee, setFee] = useState(0)
  const getAdDetail = async(cptItems) => {
    setIsSubmiting(true)

    // 执行单"SELF","EXCLUSIVE_MEDIA"详情使用原广告主接口 其它获取详情用媒体主详情接口
    const isAdExec = !mallOrderMediaId
    const url = isAdExec ? `/mall/order/getOrderPlanDetail` : `/mall/media/order/getOrderMedia`
    const params = isAdExec ? {
      mallOrderPlanId,
    } : {
      mallOrderMediaId,
    }

    const { data, code } = await util.request({
      method: isAdExec ? 'POST' : 'GET',
      url,
      data: isAdExec ? params : null,
      params: isAdExec ? null : params
    })
    if (code === 1) {
      setAd(data)
      await getOrderTotal(data, cptItems)
      setIsSubmiting(false)
    }
  }
  const getOrderTotal = async (ad, cptItems) => {
    const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `/mall/order/getOrderTotal`,
      data: {
        mallOrderPlanIds: [mallOrderPlanId],
      },
    })

    if (code === 1) {
      const oTotal = data.find(i => i.mallOrderPlanId === mallOrderPlanId)
      setAd(state => {
        return {
          ...state,
          orderTotal: oTotal,
        }
      })
      // 实付价 挂牌价
      const { planAmount, stickerPlanAmount } = oTotal
      const { planAim, deliveryContentDuration } = ad
      const isRemo = planAim !== 'EXCLUSIVE_MEDIA' && planAim !== 'SELF'
      const tempFee = cptItems.find(i => i.itemKey == ad.deliveryContentDuration)?.itemValue
      const amount = isRemo ? deliveryContentDuration * fee : parseInt(stickerPlanAmount) - parseInt(planAmount)
      setFee(tempFee)
      setFeeAmount(amount)
      setHasFreeFee(isRemo)
    }
  }

  useEffect(() => {
    init()  
  }, [])
  const init = async() => {
    const cptItems = await findEnumItemList()
    getAdDetail(cptItems)
  }
  const [technicFeeOwnCpt, setTechnicFeeOwnCpt] = useState<any>([])
  const findEnumItemList = async() => {
    // 是否要收费
    const key = 'TECHNIC_FEE_OWN_CPT'
    const params = {
      groupKeyArray: key,
    }
    const { data, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: params,
    })
    const cptItems = data[key].slice(0, 4)
    setTechnicFeeOwnCpt(cptItems)
    return cptItems
  }

  return (
    <Modal 
      title="智能刊播服务费详情" 
      width={ 1104 }
      visible={ isModalVisible } 
      footer={ null }
      closeIcon=""
      onCancel={() => { setIsModalVisible(false) }}
      >
      <div className={ `${ styles['technic-price-total'] }` }><span className={ `${ styles['text-int'] }` }>￥</span>{ hasFreeFee ? 0 : util.tools.splitNum2String(ad?.orderTotal?.planAmount).int }{ hasFreeFee ? '' : util.tools.splitNum2String(ad?.orderTotal?.planAmount).dec }</div>

      <div className={ `${ styles['technic-price-list'] }` }>
        <div className={ `${ styles['technic-price-item'] }` }>
          <div className={ `${ styles['technic-price-item-label'] }` }>{ ad?.deliveryContentDuration }秒以内广告的智能刊播服务费单价：</div>
          <div className={ `${ styles['technic-price-item-value'] }` }>{ fee }元/天/点位</div>
        </div>
        <div className={ `${ styles['technic-price-item'] }` }>
          <div className={ `${ styles['technic-price-item-label'] }` }>
          本单自动化上刊点位投放总量
            <Tooltip placement="top" title='所有自动化上刊点位投放总天数之和'>
              <span className={ `${ styles['icon-question'] } ifont i-question` }></span>
            </Tooltip>：
          </div>
          <div className={ `${ styles['technic-price-item-value'] }` }>{ ad?.orderTotal?.payInnerTotal }</div>
        </div>
        <div className={ `${ styles['technic-price-item'] }` }>
          <div className={ `${ styles['technic-price-item-label'] }` }>智能刊播技术服务费用：</div>
          <div className={ `${ styles['technic-price-item-value'] } ${ styles['total'] } ${ !hasFreeFee ? styles['special'] : '' }` }>￥<span className={ `${ styles['text-int'] }` }>{ hasFreeFee ? util.tools.splitNum2String(feeAmount).int : util.tools.splitNum2String(ad?.orderTotal?.planAmount).int }</span>{ hasFreeFee ? util.tools.splitNum2String(feeAmount).dec : util.tools.splitNum2String(ad?.orderTotal?.planAmount).dec }</div>
        </div>
        {
          hasFreeFee && (
            <div className={ `${ styles['technic-price-item'] }` }>
              <div className={ `${ styles['technic-price-item-label'] }` }>智能刊播服务费用限时减免：</div>
              <div className={ `${ styles['technic-price-item-value'] } ${ styles['total'] } ${ styles['special'] }` }>-￥<span className={ `${ styles['text-int'] }` }>{ util.tools.splitNum2String(feeAmount).int }</span>{ util.tools.splitNum2String(feeAmount).dec }</div>
            </div>
          )
        }
      </div>

      <div className={ styles['modal-top'] }>
        <div className={ `${ styles['rule-tit'] }` }>费用说明</div>
        <div className={ `${ styles['rule-tips'] }` }>· 智能刊播服务费只针对已实现全自动化上刊的影院进行收取</div>
        <div className={ `${ styles['rule-tips'] }` }>· 热幕订单免收智能刊播服务费</div>
      </div>
      <div className={ styles['modal-cont'] } style={{ height: isFold ? '45px' : 'auto' }}>
        <div className={ styles['modal-cont-tit'] }>
          <span>计费规则</span>
          <span className={ styles['text-more'] } onClick={ toggle }>{ isFold ? '展开' : '收起' }
            <span className={ `${ styles['btn-toggle'] } imediafont i-trangle-b ${ isFold ? null : styles['top'] }` }></span></span>
        </div>
        <div className={ `${ styles['rule-cont'] } ` }>
          <div className={ `${ styles['bold'] } ${ styles['tit'] }` }>智能刊播服务费 = 对应时长广告的智能刊播服务费单价 × 所有点位投放总天数之和</div>

          <div className={ `${ styles['bold'] }` }>智能刊播服务费单价</div>
          <div className={ `${ styles['technic-rules-list'] }` }>
            <div className={ `${ styles['technic-rules-item-top'] }` }>
              <div className={ `${ styles['technic-rules-item-top-text'] }` }>广告时长</div>
              <div className={ `${ styles['technic-rules-item-top-text'] }` }>单价</div>
            </div>
            {
              technicFeeOwnCpt.map(item => {
                return (
                  <div 
                    className={ `${ styles['technic-rules-item'] }` }
                    key={ item.itemKey }
                    >
                    <div className={ `${ styles['technic-rules-item-label'] }` }>{ item.itemKey }秒以内</div>
                    <div className={ `${ styles['technic-rules-item-value'] }` }>{ item.itemValue }元/天/点位</div>
                  </div>
                )
              })
            }
          </div>

          <div className={ `${ styles['rule-tips'] } ${ styles['mb24'] }` }>以此类推，每增加15秒，单价增加0.5元/天/点位</div>

          <div className={ `${ styles['bold'] }` }>说明：</div>
          <div className={ `${ styles['rule-tips'] }` }>刊播过程中，若由于媒体商家的原因导致缺刊，智能刊播服务费不予退回。</div>
        </div>
      </div>
    </Modal>
  )
}